export const formatExtensions = (extensions) => {
    return extensions.map(item => {
        return {
            label: item.name,
            isDev: item.dev,
            version: item.version,
            subdomain: item.subdomain
        }
    })
}
