export const categories = ["Analisis", "Gestion", "Relevamientos", "Clima", "Reportes", "DON MARIO EXACTO", "Todas"] as const

export const parseCategories = {
    2: "Analisis",
    3: "Gestion",
    4: "Relevamientos",
    5: "Clima",
    6: "Reportes",
    101: "DON MARIO EXACTO",
    1: "Todas"
}

export type Category = typeof categories[number]

export const countriesFilter = ["Habilitadas en tu pais", "Todas"] as const

export type CountryFilterOption = typeof countriesFilter[number]