import { Combobox, Transition } from "@headlessui/react"
import React, { ChangeEvent, FormEvent, Fragment } from "react"
import { SearchIcon } from "./icons/SearchIcon";

interface SearchbarProps {
    placeholder: string,
    onSubmit: (e: FormEvent<HTMLFormElement>) => void
    onSearch: (value: string) => void
    value: string | unknown;
    resetInput?: () => void
    onInputChange: (e?: ChangeEvent<HTMLInputElement>) => void
    displayValue?: (item: unknown) => string
}

interface SearchbarPropsWithOptions extends SearchbarProps {
    options: { label: string, value: string }[]
    renderOptions?: never
}
interface SearchbarPropsWithRenderOptions extends SearchbarProps {
    renderOptions: () => React.ReactNode[] | React.ReactNode
    options?: never
}

type Props = SearchbarPropsWithOptions | SearchbarPropsWithRenderOptions

export const Seachbar = ({ placeholder, onSubmit, onInputChange, onSearch, resetInput, options, value, renderOptions, displayValue }: Props) => {

    return (
        <div className="relative w-full">
            <form onSubmit={onSubmit} className="flex flex-col w-full absolute items-center">
                <Combobox value={value} onChange={onSearch} nullable>
                    <Combobox.Input
                        placeholder={placeholder}
                        onChange={onInputChange}
                        className="rounded-full h-10 px-8 w-full font-thin shadow-around p-2 outline-none z-20 peer"
                        displayValue={displayValue}
                    />
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => resetInput && resetInput()}
                    >
                        <Combobox.Options className={"bg-white w-full mt-1 rounded-b-md last:border-0 max-h-80 overflow-auto scrollbar shadow-around"}>
                            {renderOptions ? renderOptions() : options.length > 0 ? options?.map((option: { label: string, value: string }) => {
                                return <Combobox.Option className={"cursor-pointer py-1 border-b border-black px-2 last:border-0"} key={option.value} value={option.label}>
                                    <span >{option.label}</span>
                                </Combobox.Option>
                            }) : <span>Not Found</span>}
                        </Combobox.Options>
                    </Transition>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className={`absolute top-6 w-full h-6 bg-white peer-ui-open:opacity-100 opacity-0`} />
                    </Transition>
                </Combobox>
                <button className="absolute right-0 p-2 bg-dark-gray rounded-full h-9 w-9 mt-0.5 mr-0.5 z-20 flex items-center justify-center"><SearchIcon role="img" title="Search Button" /></button>
            </form >
        </div>
    )
}