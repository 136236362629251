import { Listbox } from "@headlessui/react"
import { ArrowUpIcon } from "./icons/ArrowUpIcon"
import { ArrowDownIcon } from "./icons/ArrowDownIcon"
import { ReactNode } from "react"

interface DropdownProps {
    title: string
    options?: Readonly<string[]> | string[]
    variant: DropdownVariant
    selected: string | number | { label: string; value: number }
    displayed: string;
    disabled?: boolean
    onChange: (value: string | unknown) => void
    renderOptions?: () => ReactNode[]
}

type DropdownVariant = keyof typeof variants

const variants = {
    solid: "lg:bg-white lg:text-black bg-transparent text-white",
    outline: "bg-dark-gray lg:border-2 border-white text-white "
}

export const Dropdown = ({ title, options, variant, selected, onChange, disabled, renderOptions, displayed }: DropdownProps) => {
    return (
        <div className="relative">
            <Listbox value={selected} onChange={onChange} disabled={disabled}>
                <Listbox.Button className={`flex w-full justify-between px-2 text-xs sm:text-base py-1 rounded-md items-center ${variants[variant]}`}>
                    <span className="whitespace-nowrap text-ellipsis line-clamp-3 capitalize">{displayed || title}</span>
                    <ArrowDownIcon className={`ui-open:hidden block stroke-white ${variant === "solid" ? "stroke-white lg:stroke-black" : "stroke-white"}`} />
                    <ArrowUpIcon className={`ui-open:block hidden stroke-white ${variant === "solid" ? "stroke-white lg:stroke-black" : "stroke-white"}`} />
                </Listbox.Button>
                <Listbox.Options className="flex flex-col rounded-md bg-white mt-1 absolute w-full z-30 shadow-around">
                    {renderOptions ? renderOptions() :
                        options.map((option) => {
                            return <Listbox.Option
                                value={option}
                                key={option} as="span"
                                className={({ active }) => ` w-full px-2 py-1 border-b border-light-gray last:border-b-0 ${active ? "bg-light-blue" : "bg-white"}`}
                            >
                                {option}
                            </Listbox.Option>
                        })
                    }
                </Listbox.Options>
            </Listbox>
        </div>
    )
}