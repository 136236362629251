import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import esARTranslations from './locales/es_AR.json'
import enUSTranslations from './locales/en_US.json'
import ptBRTranslations from './locales/pt_BR.json'
import esESTranslation from './locales/es_ES.json'
import frFRTranslation from './locales/fr_FR.json'

// Definimos los idiomas a los que vamos a traducir la extensión

const resources = {
    en_US: {
        translation: enUSTranslations
    },
    es_AR: {
        translation: esARTranslations
    },
    pt_BR: {
        translation: ptBRTranslations
    },
    es_ES: {
        translation: esESTranslation
    },
    fr_FR: {
        translation: frFRTranslation
    }
}

// Iniciamos i18n y definimos un idioma por defecto, en este caso Español de Argentina
i18n.use(initReactI18next).init({
    resources,
    lng: 'es_AR',
    fallbackLng: 'es_AR'
})

export default i18n