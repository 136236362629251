import Image from "next/image"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { FacebookIcon } from "./icons/FacebookIcon"
import { TwitterIcon } from "./icons/TwitterIcon"
import { LinkedInIcon } from "./icons/LinkedInIcon"
import { YoutubeIcon } from "./icons/YoutubeIcon"
import { InstagramIcon } from "./icons/InstagramIcon"


const LINKS = {
    home: {
        pt_BR: "https://www.auravant.com/pt/home-pt/",
        es_AR: "https://www.auravant.com/",
        es_ES: "https://www.auravant.com/",
        en_US: "https://www.auravant.com/en/home-en/"
    },
    companies: {
        en_US: "https://www.auravant.com/en/companies/",
        es_AR: "https://www.auravant.com/empresas/",
        es_ES: "https://www.auravant.com/empresas/",
        pt_BR: "https://www.auravant.com/pt/empresas-pt/"
    },
    product: {
        pt_BR: "https://www.auravant.com/pt/produto/",
        es_AR: "https://www.auravant.com/producto/",
        es_ES: "https://www.auravant.com/producto/",
        en_US: "https://www.auravant.com/en/product/"
    },
    pricing: {
        en_US: "https://www.auravant.com/en/pricing-en/",
        es_AR: "https://www.auravant.com/pricing/",
        es_ES: "https://www.auravant.com/pricing/",
        pt_BR: "https://www.auravant.com/pt/pricing-pt/"
    },
    "help-center": {
        en_US: "https://www.auravant.com/en/help/",
        es_AR: "https://www.auravant.com/ayuda/",
        es_ES: "https://www.auravant.com/ayuda/",
        pt_br: "https://www.auravant.com/pt/ajuda/"

    },
    contact: {
        en_US: "https://www.auravant.com/en/contact/",
        es_AR: "https://www.auravant.com/contacto/",
        es_ES: "https://www.auravant.com/contacto/",
        pt_BR: "https://www.auravant.com/pt/contato/"
    },
    articles: {
        en_US: "https://www.auravant.com/en/articles/",
        es_AR: "https://www.auravant.com/blog/",
        es_ES: "https://www.auravant.com/blog/",
        pt_BR: "https://www.auravant.com/pt/artigos/"
    },
    "about-us": {
        en_US: "https://www.auravant.com/en/about-us/",
        es_AR: "https://www.auravant.com/sobre-nosotros/",
        es_ES: "https://www.auravant.com/sobre-nosotros/",
        pt_BR: "https://www.auravant.com/pt/sobre-nos/"
    },
    "our-culture": {
        en_US: "https://www.auravant.com/en/our-culture/",
        es_AR: "https://www.auravant.com/nuestra-cultura/",
        es_ES: "https://www.auravant.com/nuestra-cultura/",
        pt_BR: "https://www.auravant.com/pt/nossa-cultura/"
    },
    "auravant-ready": {
        en_US: "https://www.auravant.com/en/auravant-ready-en/",
        es_AR: "https://www.auravant.com/auravant-ready/",
        es_ES: "https://www.auravant.com/auravant-ready/",
        pt_BR: "https://www.auravant.com/pt/auravant-ready-pt/"
    },
    partners: {
        en_US: "https://www.auravant.com/en/partners-2/",
        es_AR: "https://www.auravant.com/partners/",
        es_ES: "https://www.auravant.com/partners/",
        pt_BR: "https://www.auravant.com/pt/parceiros/"
    },
    developers: {
        en_US: "https://developers.auravant.com/",
        es_AR: "https://developers.auravant.com/",
        es_ES: "https://developers.auravant.com/",
        pt_BR: "https://developers.auravant.com/"
    },
    "work-with-us": {
        en_US: "https://www.auravant.com/en/work-with-us/",
        es_AR: "https://www.auravant.com/trabaja-con-nosotros/",
        es_ES: "https://www.auravant.com/trabaja-con-nosotros/",
        pt_BR: "https://www.auravant.com/pt/trabalhar-conosco/"
    },
    press: {
        en_US: "https://www.auravant.com/en/press/",
        es_AR: "https://www.auravant.com/prensa/",
        es_ES: "https://www.auravant.com/prensa/",
        pt_BR: "https://www.auravant.com/pt/imprensa/"
    },
    "terms-and-conditions": {
        en_US: "https://www.auravant.com/en/terms-en/",
        es_AR: "https://www.auravant.com/terms/",
        es_ES: "https://www.auravant.com/terms/",
        pt_BR: "https://www.auravant.com/pt/terms-pt/"
    }

}

const AURAVANT_LINKS = [
    {
        label: "home",

    },
    {
        label: "companies",

    },
    {
        label: "product",

    },
    {
        label: "pricing",

    },
    {
        label: "help-center",

    },
    {
        label: "contact",

    },
    {
        label: "articles",

    },
]
const COMPANY_LINKS = [
    {
        label: "about-us",
        link: ""
    },
    {
        label: "our-culture",
        link: ""
    },
    {
        label: "auravant-ready",
        link: ""
    },
    {
        label: "partners",
        link: ""
    },
    {
        label: "developers",
        link: ""
    },
    {
        label: "work-with-us",
        link: ""
    },
    {
        label: "press",
        link: ""
    },
    {
        label: "terms-and-conditions",
        link: ""
    },
]
const CONTACT_LINKS = [
    {
        title: "argentina",
        links: [
            {
                label: "El Salvador 5218 C1414BPV",
                link: ""
            },
            {
                label: "Ciudad de Buenos Aires",
                link: ""
            },

        ]
    },
    {
        title: "españa",
        links: [
            {
                label: "C. de Henri Dunant 17, 28036",
                link: ""
            },
            {
                label: "Madrid",
                link: ""
            },
        ]
    },
    {
        title: "brasil",
        links: [
            {
                label: "Alameda Vicente Pinzon, 54",
                link: ""
            },
            {
                label: "Vila Olimpia, Sao Paulo",
                link: ""
            },
            {
                label: "SP, 04547-130",
                link: ""
            },
            {
                label: "+55 11942517103",
                link: ""
            },
        ]
    }
]

const SOCIAL_MEDIA_LINKS = [
    {
        icon: <FacebookIcon />,
        link: {
            es_ES: "https://www.facebook.com/auravant/",
            es_AR: "https://www.facebook.com/auravant/",
            en_US: "https://www.facebook.com/auravant/",
            pt_BR: "https://www.facebook.com/people/Auravant-Brasil/100076207180136/"
        }
    },
    {
        icon: <TwitterIcon />,
        link: "https://twitter.com/auravant"
    },
    {
        icon: <LinkedInIcon />,
        link: "https://www.facebook.com/auravant/"
    },
    {
        icon: <YoutubeIcon />,
        link: "https://www.youtube.com/auravant"
    },
    {
        icon: <InstagramIcon />,
        link: {
            es_ES: "https://www.instagram.com/auravant/",
            es_AR: "https://www.instagram.com/auravant/",
            en_US: "https://www.instagram.com/auravant/",
            pt_BR: "https://www.instagram.com/auravant.br/"
        }
    }
]


export const AuraFooter = () => {
    const { t } = useTranslation();
    const { query } = useRouter();
    return (
        <footer>
            <div className="min-h-[200px] p-8 bg-[#0e8dd3] flex flex-col justify-center items-center gap-8">
                <span className="text-white text-xl">{t("auraFooter.title")}</span>
                <a href="mailto:soporte@auravant.com" className="bg-white font-semibold text-aura-blue rounded-full py-4 px-8 text-xl">
                    {t("auraFooter.email")}
                </a>
            </div>
            <div className="bg-[#1584c5] h-[410px] pt-14 flex">
                <div className="text-[rgba(255,255,255,0.94)] flex gap-32 justify-center w-full">
                    <div className="flex flex-col">
                        <Image src="https://www.auravant.com/wp-content/uploads/2020/03/logo-blanco-2.png" alt="aura-logo" width={166} height={43} className="object-scale-down " />
                        <a href="https://www.auravant.com/auravant-android">
                            <Image src="https://www.auravant.com/wp-content/themes/Avada/assets/css/auravant/img/logo-google-play.svg" alt="aura-logo" width={166} height={43} className="object-scale-down " />
                        </a>
                        <a href="https://www.auravant.com/auravant-ios">
                            <Image src="https://www.auravant.com/wp-content/themes/Avada/assets/css/auravant/img/logo-app-store.svg" alt="aura-logo" width={166} height={43} className="object-scale-down " />
                        </a>
                    </div>
                    <div >
                        <h2 className="pb-8">AURAVANT</h2>
                        <ul className="text-xs">
                            {AURAVANT_LINKS.map((l, index) => {
                                return (
                                    <li key={index}>
                                        <a href={`${LINKS[l.label][query?.locale?.toString()]}`}>{t(`auraFooter.labels.auravant.${l.label}`)}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <h2 className="pb-8 ">COMPANIA</h2>
                        <ul className="text-xs">
                            {COMPANY_LINKS.map(l => {
                                return (
                                    <li key={l.label}>
                                        <a href={`${LINKS[l.label][query?.locale?.toString()]}`}>{t(`auraFooter.labels.company.${l.label}`)}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <h2 className="pb-8 ">CONTACTO</h2>
                        <ul className="text-xs">
                            {CONTACT_LINKS.map(l => {
                                return (
                                    <li key={l.title}>
                                        <span className="capitalize font-semibold">{l.title}</span>
                                        <ul>
                                            {l.links.map(link => {
                                                return <li key={link.link}><a>{link.label}</a></li>
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <h2 className="italic mb-2">Incubed By</h2>
                        <Image src="https://www.auravant.com/wp-content/uploads/2021/11/ESA-BIC.png" width={120} height={43} alt="business-incubation-centre-madrid-logo" />
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#1584c5]">
                <div className="flex items-center">
                    <ul className="flex p-4 gap-8">
                        {SOCIAL_MEDIA_LINKS.map((sml, index) => {
                            let currentLink;
                            if (sml.link instanceof String || typeof sml.link === "string") {
                                currentLink = sml.link
                            } else {
                                currentLink = sml.link[query?.locale?.toString()]
                            }
                            return <li key={index}><a href={currentLink}>{sml.icon}</a></li>
                        })}

                    </ul>
                    <span className="text-white opacity-50">
                        /Auravant
                    </span>
                </div>
                <span className="text-white opacity-50 pb-4">Copyright {new Date().getFullYear()} Auravant | {t("auraFooter.rights-reserved")}</span>
            </div>
        </footer>
    )
}