import { Extension } from "../types";
const BACKEND_URL = "https://api.auravant.com/api/mktplace/apps"

export const getExtensions = async () => {
    try {
        const res = await fetch(BACKEND_URL)
        if (!res.ok) throw new Error("Error on the response")

        return await res.json() as {
            code: number;
            apps: Extension[]
        };
    } catch (e) {
        console.log(e)
    }
}

export const getCategories = async () => {
    try {
        const res = await fetch(BACKEND_URL)
        if (!res.ok) throw new Error("Error on the response")
        const extensions = await res.json() as {
            code: number;
            apps: Extension[]
        };
        return Array.from(new Set(extensions.apps.map(extension => extension.id_group)))
    } catch (e) {
        console.log(e)
    }
}