import { FormEvent, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Seachbar } from "./Searchbar";
import { Combobox, Listbox } from "@headlessui/react";
import { Dropdown } from "./Dropdown";
import { useRouter } from "next/router";
import { CountryFilterOption, countriesFilter, parseCategories } from "../utils/constants";
import { formatExtensions } from "../utils/formatExtensions";
import useSWR from "swr";
import { getCategories, getExtensions } from "../utils/api";
import { useTranslation } from "react-i18next";
import { AuraHeader } from "./AuraHeader";
import { AuraFooter } from "./AuraFooter";

type LayoutProps = PropsWithChildren

const INITIAL_ROUTE_VALUE = 1

export const Layout = ({ children }: LayoutProps) => {
    const { data: extensions } = useSWR("extensions", getExtensions)
    const { data: categories } = useSWR("categories", getCategories)
    const { t } = useTranslation()
    const [allCategories, setAllCategories] = useState([])
    const router = useRouter()
    const [input, setInput] = useState('')
    const [currentOption, setCurrentOption] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(INITIAL_ROUTE_VALUE)
    const [countryFilterOption, setCountryFilterOption] = useState<CountryFilterOption>("Habilitadas en tu pais")

    const formattedExtensions = formatExtensions(extensions?.apps || [])

    const filteredData = useMemo(() => {
        return formattedExtensions.filter((extension) => {
            return extension.label.toLowerCase().includes(input.toLowerCase())
        })
    }, [input])

    const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!currentOption) {
            router.push(`/${router.query.locale}`)
            return;
        }
        router.push(`/${router.query.locale}/extension/${currentOption.subdomain}`)
    }

    const handleSelectCountryFilterChange = (countryFilter: CountryFilterOption) => {
        setCountryFilterOption(countryFilter)
        if (countryFilter === "Habilitadas en tu pais") {
            router.push(`/${router.query.locale}`)
            return
        }

        router.push(`/${router.query.locale}/country`)
    }

    const handleSelectCategoryChange = (category) => {
        setSelectedCategory(category)
        if (category === INITIAL_ROUTE_VALUE) {
            router.push(`/${router.query.locale}`)
            return;
        }
        router.push(`/${router.query.locale}/category/${category}`)
    }

    useEffect(() => {
        if (!categories) return;

        setAllCategories(categories)
        setAllCategories((prev) => [...prev, INITIAL_ROUTE_VALUE])
    }, [categories])

    useEffect(() => {
        if (router.query.extension) {
            setSelectedCategory(INITIAL_ROUTE_VALUE)
            return;
        }

        if (!selectedCategory) return;

        if (Object.keys(router.query).length === 0) {
            setSelectedCategory(INITIAL_ROUTE_VALUE)
            return;
        }

        const currentCategory = router.query.category

        if (selectedCategory !== Number(currentCategory)) {
            setSelectedCategory(Number(currentCategory))
        }

        if (currentCategory) {
            setCurrentOption(null)
        }
    }, [router.query])

    return <>
        <AuraHeader />
        <header className="bg-dark-gray w-full h-[100px] lg:h-36 flex lg:justify-between lg:items-center px-3 lg:px-16 flex-col lg:flex-row pt-3 ">
            <div className="flex items-center lg:gap-8 gap-2 flex-col lg:flex-row w-full">
                <div className="flex items-start w-full lg:w-[22.125rem]">
                    <div className="mb-10 lg:w-[22.125rem] w-80 z-30">
                        <Seachbar placeholder={t("searchbar.buscar") + "..."}
                            displayValue={(option: { label: string }) => option?.label}
                            value={currentOption}
                            onSearch={setCurrentOption}
                            onSubmit={handleSearchSubmit}
                            onInputChange={(e) => setInput(e.target.value)}
                            renderOptions={() => (
                                filteredData.length ? filteredData.map(option =>
                                    <Combobox.Option className="cursor-pointer py-1 border-b font-thin border-black px-2 last:border-0 flex h-full items-center justify-between" key={option.label + option.version} value={option}>
                                        <span className="font-thin">{option.label}</span>
                                        {option.isDev && <span className="text-xs text-gray-500">Dev</span>}
                                    </Combobox.Option>
                                ) : <span className="p-2">{t("searchbar.not-found")}</span>)
                            }
                        />
                    </div>
                </div>
                <div className="flex lg:gap-8 gap-2 items-center w-full lg:justify-between">
                    <div className="flex lg:gap-8 gap-2 items-center">
                        <div className="w-32 md:w-48">
                            <Dropdown title={t("categories.categories")} variant="solid"
                                renderOptions={() => allCategories.map(category => {
                                    return <Listbox.Option
                                        value={category}
                                        key={category} as="span"
                                        className={({ active }) => ` w-full px-2 py-1 border-b border-light-gray last:border-b-0 ${active ? "bg-light-blue" : "bg-white"}`}
                                    >
                                        {t(`categories.${parseCategories[category as keyof typeof parseCategories]?.toLowerCase()}`)}
                                    </Listbox.Option>
                                })}
                                disabled={allCategories.length === 0}
                                displayed={parseCategories[selectedCategory as keyof typeof parseCategories]?.toLowerCase() && t(`categories.${parseCategories[selectedCategory as keyof typeof parseCategories]?.toLowerCase()}`)} selected={selectedCategory} onChange={handleSelectCategoryChange} />
                        </div>
                    </div>
                    <div>
                        <div className="w-40 lg:w-64">
                            <Dropdown selected={countryFilterOption} displayed={countryFilterOption === "Todas" ? t("categories.todas") : t("country-filter.country-filtered")} variant="outline" title="Habilitadas en tu pais" renderOptions={() => countriesFilter.map(countryFilter => {
                                return <Listbox.Option
                                    value={countryFilter}
                                    key={countryFilter} as="span"
                                    className={({ active }) => ` w-full px-2 py-1 border-b border-light-gray last:border-b-0 ${active ? "bg-light-blue" : "bg-white"}`}
                                >
                                    {countryFilter === "Todas" ? t("categories.todas") : t("country-filter.country-filtered")}
                                </Listbox.Option>
                            })} onChange={handleSelectCountryFilterChange} options={countriesFilter} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
        {children}
        <AuraFooter />
    </>
}