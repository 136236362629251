import Image from "next/image"
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useWindowSize } from "../hooks/useWindowWidth";
import { ArrowDownIcon } from "./icons/ArrowDownIcon";
import Link from "next/link";

const LABELS = [
    // {
    //     name: "auraHeader.links.empresas",
    //     id: 1,

    // },
    // {
    //     name: "auraHeader.links.precios",
    //     id: 2,

    // },
    {
        name: "auraHeader.links.ayuda",
        id: 3,

    },
    {
        name: "auraHeader.links.contacto",
        id: 4,

    },
    // {
    //     name: "auraHeader.links.blog",
    //     id: 5,

    // },
    {
        name: "auraHeader.links.comienza-gratis",
        id: 6,

    },
    {
        name: "auraHeader.links.ingresar",
        id: 7,

    }
]

const LABELS_PT = [
    {
        name: "auraHeader.links.extensiones",
        id: 4,
        link: "https://www.auravant.com/pt/extensions-pt/"
    },
    {
        name: "auraHeader.links.ayuda",
        id: 3,
        link: "https://www.auravant.com/pt/ajuda/"
    },
    {
        name: "auraHeader.links.comienza-gratis",
        id: 6,
        link: "https://www.auravant.com/pt/empresas-pt/contact-b2b-pt/"
    },
    {
        name: "auraHeader.links.comenzar",
        id: 7,
        link: "https://accounts.auravant.com/register?locale=pt_BR"
    },
    {
        name: "auraHeader.links.ingresar",
        id: 8,
        link: "https://auraview.auravant.com/"
    }
]

const LINKS = [
    {
        en_US: "https://www.auravant.com/en/help/",
        es_AR: "https://www.auravant.com/ayuda/",
        es_ES: "https://www.auravant.com/ayuda/",

    },
    {
        en_US: "https://www.auravant.com/en/contact/",
        es_AR: "https://www.auravant.com/contacto/",
        es_ES: "https://www.auravant.com/contacto/",
    },
    {
        en_US: "https://accounts.auravant.com/register",
        es_AR: "https://accounts.auravant.com/register",
        es_ES: "https://accounts.auravant.com/register",
    },
    {
        en_US: "https://auraview.auravant.com/",
        es_AR: "https://auraview.auravant.com/",
        es_ES: "https://auraview.auravant.com/",
    }
]

const MOBILE_LINKS = [
    {
        en_US: "https://auraview.auravant.com/",
        es_AR: "https://auraview.auravant.com/",
        es_ES: "https://auraview.auravant.com/",
        pt_BR: "https://auraview.auravant.com/"
    },

    {
        en_US: "https://www.auravant.com/en/help/",
        es_AR: "https://www.auravant.com/ayuda/",
        es_ES: "https://www.auravant.com/ayuda/",
        pt_BR: "https://www.auravant.com/pt/ajuda/"
    },
    {
        en_US: "https://www.auravant.com/en/contact/",
        es_AR: "https://www.auravant.com/contacto/",
        es_ES: "https://www.auravant.com/contacto/",
        pt_BR: "https://www.auravant.com/pt/contato/"
    },
]



const LANGS = {
    "es_AR": "ESPAÑOL",
    "es_ES": "ESPAÑOL",
    "pt_BR": "Português",
    "en_US": "ENGLISH"
}

const LANGS_SELECTOR = [
    { label: "ESPAÑOL", link: "es_AR" },
    { label: "Português", link: "pt_BR" },
    { label: "ENGLISH", link: "en_US" }
]


const DRAWER_LABELS = [
    {
        name: "auraHeader.links.ingresar",
        id: 7,

    },

    {
        name: "auraHeader.links.ayuda",
        id: 3,

    },
    {
        name: "auraHeader.links.contacto",
        id: 4,

    },

]

const SOLID_BUTTON_CLASSNAMES = {
    es_AR: "[&:nth-child(3)]:bg-aura-green [&:nth-child(3)]:whitespace-nowrap [&:nth-child(3)]:text-stone-100 [&:nth-child(6)]:hover:bg-aura-blue",
    es_ES: "[&:nth-child(3)]:bg-aura-green [&:nth-child(3)]:whitespace-nowrap [&:nth-child(3)]:text-stone-100 [&:nth-child(6)]:hover:bg-aura-blue",
    en_US: "[&:nth-child(3)]:bg-aura-green [&:nth-child(3)]:whitespace-nowrap [&:nth-child(3)]:text-stone-100 [&:nth-child(6)]:hover:bg-aura-blue",
    fr_FR: "[&:nth-child(3)]:bg-aura-green [&:nth-child(3)]:whitespace-nowrap [&:nth-child(3)]:text-stone-100 [&:nth-child(6)]:hover:bg-aura-blue",
    pt_BR: "[&:nth-child(4)]:bg-aura-green [&:nth-child(4)]:whitespace-nowrap [&:nth-child(4)]:text-stone-100 [&:nth-child(4)]:hover:bg-aura-blue"
}

const APP_DOWNLOAD_LINKS = {
    "es_AR": "https://www.auravant.com/app-download-es/",
    "es_ES": "https://www.auravant.com/app-download-es/",
    "pt_BR": "https://www.auravant.com/pt/app-download-pt/",
    "en_US": "https://www.auravant.com/en/app-download-en/"

}


export const AuraHeader = () => {
    const { query: { locale } } = useRouter();
    const windowSize = useWindowSize()

    if (locale?.toString() === "pt_BR") {
        if (windowSize.width > 800) {
            return <PT_HEADER />
        }
        return <MobileHeader />
    }

    if (locale?.toString() !== "pt_BR") {
        if (windowSize.width > 800) {
            return <ES_EN_HEADER />
        }
        return <MobileHeader />
    }

    return (
        <>

            {locale?.toString() === "pt_BR" ? <PT_HEADER /> : <ES_EN_HEADER />}
            <MobileHeader />
        </>
    )
}

const OUTLINED_BUTTON_CLASSNAME = " [&:nth-child(3)]:border [&:nth-child(3)]:border-aura-green [&:nth-child(3)]:text-aura-green [&:nth-child(3)]:hover:bg-aura-green [&:nth-child(3)]:hover:text-stone-100"

const PT_HEADER = () => {
    const { t } = useTranslation();
    const { query: { locale } } = useRouter();
    return <header className="flex flex-col my-4 px-4 xl:my-0 xl:flex-row justify-between h-[5.25rem] font-thin lg:px-64">
        <Image src="https://www.auravant.com/wp-content/uploads/2021/04/Logotipo-Auravant.png" alt="aura-logo" width={166} height={43} className="object-scale-down" />
        <nav className="flex items-center ">
            <ul className="flex items-center w-full gap-2">
                {LABELS_PT.map(({ name, id, link }) => <li
                    className={`text-gray-700 hover:text-gray-400 duration-300 px-4 py-2 rounded-full 
                 ${SOLID_BUTTON_CLASSNAMES[locale as keyof typeof SOLID_BUTTON_CLASSNAMES]} ${OUTLINED_BUTTON_CLASSNAME}`}
                    key={id}>
                    <a href={link}>{t(name).toUpperCase()}</a>
                </li>)}
                <LangSelector langs={LANGS_SELECTOR} defaultLang={LANGS[locale?.toString()]?.toUpperCase()} />
            </ul>
        </nav>
    </header>
}


const ES_EN_HEADER = () => {
    const { t } = useTranslation();
    const { query: { locale } } = useRouter();
    return <header className="flex flex-col my-4 px-4 xl:my-0 xl:flex-row xl:justify-between h-[5.25rem] font-thin lg:px-64">
        <Image src="https://www.auravant.com/wp-content/uploads/2021/04/Logotipo-Auravant.png" alt="aura-logo" width={166} height={43} className="object-scale-down" />
        <nav className="flex items-center ">
            <ul className="flex items-center w-full xl:gap-2">
                {LABELS.map(({ name, id }, index) => <li
                    className={`${SOLID_BUTTON_CLASSNAMES[locale as keyof typeof SOLID_BUTTON_CLASSNAMES]} text-gray-700 hover:text-gray-400 duration-300  px-4 py-2 rounded-full`} key={id}>
                    <a href={LINKS[index][locale?.toString()]}>{t(name).toUpperCase()}</a>
                </li>)}
                <LangSelector langs={LANGS_SELECTOR} defaultLang={LANGS[locale?.toString()]?.toUpperCase()} />
            </ul>
        </nav>
    </header>
}

const LangSelector = ({ langs, defaultLang }: { langs: { label: string, link: string }[], defaultLang: string }) => {
    const [isOpen, setIsOpen] = useState(false)
    const filteredLangs = langs.filter(l => l.label.toLowerCase() !== defaultLang?.toLowerCase())
    return (
        <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} className="relative">
            <span className="text-gray-700">{defaultLang}</span>
            <ul className={`${isOpen ? "visible" : "invisible"} absolute z-20 bg-[#f2efef] text-gray-700 px-4 py-2 gap-4 `}>
                {filteredLangs.map(lang => {
                    return <li>
                        <Link href={"/" + lang.link}>{lang.label?.toUpperCase()}</Link>
                    </li>
                })}
            </ul>
        </div>
    )
}

const MobileHeader = () => {
    const router = useRouter()
    const [drawerOpen, setDrawerOpen] = useState(false)
    return (
        <>
            <header className={`sticky top-0 p-4 shadow-lg  mb-2 flex justify-between z-50 bg-white ${drawerOpen ? "rounded-none" : "rounded-b-[25px]"} duration-300`}>
                <div className="flex gap-4">
                    <button onClick={() => setDrawerOpen(prev => !prev)}>
                        <Image src={"https://www.auravant.com/wp-content/themes/Avada/assets/css/auravant/img/icon-menu-mov-blue.svg"} alt="menu-icon" width={20} height={20} />
                    </button>
                    <Image src="https://www.auravant.com/wp-content/uploads/2021/04/Logotipo-Auravant.png" alt="aura-logo" width={120} height={30} className="object-scale-down" />
                </div>
                <div className="flex items-center gap-4">
                    <a href={APP_DOWNLOAD_LINKS[router.query?.locale?.toString()]} target="_blank">
                        <Image src={"https://www.auravant.com/wp-content/uploads/2023/04/login-azul.svg"} alt="profile-icon" height={20} width={20} />
                    </a>
                    <a target="_blank" href="https://accounts.auravant.com/register" className="bg-aura-green text-sm text-stone-100  px-4 py-0.5 rounded-full font-semibold">COMIENZA</a>
                </div>
            </header>
            <Drawer isOpen={drawerOpen} />
        </>
    )
}

const LangSelectorMobile = ({ langs, defaultLang }: { langs: { label: string, link: string }[], defaultLang: string }) => {
    const [isOpen, setIsOpen] = useState(false)
    const filteredLangs = langs.filter(l => l.label.toLowerCase() !== defaultLang?.toLowerCase())
    return (
        <div>
            <div className="flex justify-between px-8 my-4">
                <span className="text-xs text-aura-blue">{defaultLang}</span>
                <button onClick={() => setIsOpen((prev) => !prev)}>
                    <ArrowDownIcon color="black" className="text-xs" />
                </button>
            </div>
            <div className={`grid ${isOpen ? "[grid-template-rows:1fr] absolute top-[200px]" : "[grid-template-rows:0fr] absolute top-[200px]"} duration-300 bg-white shadow-lg w-full z-50`}>
                <ul className={`${isOpen ? "mb-4" : "mb-0"} overflow-hidden text-sm text-aura-blue px-8 flex flex-col gap-4`}>
                    {filteredLangs.map(lang => {
                        return <li key={lang.label}>
                            <Link href={"/" + lang.link}>- {lang.label?.toUpperCase()}</Link>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}

const Drawer = ({ isOpen }: { isOpen: boolean }) => {
    const { t } = useTranslation()
    const router = useRouter()
    return (
        <div className={`grid  mt-[3.9rem] ${isOpen ? "[grid-template-rows:1fr] fixed top-0" : "[grid-template-rows:0fr] fixed top-0"} duration-300 bg-white shadow-lg w-full z-50`}>
            <div className="overflow-hidden z-50">
                <ul>
                    {DRAWER_LABELS.map(({ name, id }, index) => {
                        return <li key={id} className="text-aura-blue  text-xs p-4 px-8 flex gap-4 first:mb-2 items-center">
                            {index === 0 && <Image src={"https://www.auravant.com/wp-content/uploads/2023/04/login-azul.svg"} alt="profile-icon" height={20} width={20} />}
                            <a className="hover:text-gray-700 duration-300" target="_blank" href={MOBILE_LINKS[index][router.query?.locale?.toString()]}>{t(name).toUpperCase()}</a>
                        </li>
                    })}
                    <LangSelectorMobile langs={LANGS_SELECTOR} defaultLang={LANGS[router.query.locale?.toString()]?.toUpperCase()} />
                </ul>
            </div>
        </div>
    )
}