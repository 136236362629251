import "../styles/globals.css";
import { Layout } from "../components/Layout";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(router.query.locale || "en_US");
  }, [router.query.locale]);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
